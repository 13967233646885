import React from 'react'
import { Button, Spin, Typography } from 'antd'
import { VOTE } from 'constants/urls'
import { status } from 'constants/enums'
import { useWebSocket } from 'ws/WebSocketContext'
import { checkIfUserVoted } from 'helpers/helpers'
const { Title } = Typography

interface iActions {
  selectedCardId: string | null
}

const Actions = ({ selectedCardId }: iActions) => {
  const { game, sendMessage, currentPlayer, isAdmin, isPlayer } = useWebSocket()

  const onChoose = () => {
    const choice = {
      gameId: game?.id,
      playerId: currentPlayer?.id,
      cardId: selectedCardId,
    }
    sendMessage(VOTE, choice)
  }

  return (
    <>
      {isAdmin && (
        <>
          <Title level={4}>Идет голосование. Ожидаем игроков</Title>
          <Spin />
        </>
      )}
      {isPlayer &&
        game?.status === status.VOTING &&
        !checkIfUserVoted(game?.roundState?.votes, currentPlayer?.id) && (
          <>
            <Button type="primary" onClick={onChoose}>
              Проголосовать
            </Button>
          </>
        )}
    </>
  )
}

export default Actions
