import React, { useState } from 'react'
import styles from './PlayerHand.module.scss'
import Actions from './Actions'
import Slider from '../Slider/Slider'
import GameInfo from './GameInfo'
import { useWebSocket } from 'ws/WebSocketContext'
import { iCard } from 'types/game'
import Cards from 'components/Cards/Cards'

const PlayerHand = () => {
  const { currentPlayer } = useWebSocket()

  const [selectedCardId, setSelectedCardId] = useState<string | null>(null)

  const handleCardClick = (cardId: string) => {
    setSelectedCardId(cardId)
  }

  const [isSliderOpen, setIsSliderOpen] = useState(false) // Для управления видимостью слайдера
  const [initialSlide, setInitialSlide] = useState<number | undefined>(0) // Индекс начальной карты в слайдере

  const handleCardDoubleClick = (card: iCard) => {
    const index: number | undefined = currentPlayer?.hand?.findIndex(
      (c: iCard) => c.id === card.id
    )
    if (index !== -1) {
      setInitialSlide(index)
      setIsSliderOpen(true)
    }
  }

  const renderSlider = () => {
    if (!isSliderOpen) return null
    return (
      <Slider
        cards={currentPlayer?.hand}
        initialSlide={initialSlide}
        onClose={() => setIsSliderOpen(false)}
      />
    )
  }

  const renderHand = () => {
    return (
      <div className={styles.playerHand}>
        {renderSlider()}
        <GameInfo />
        <p>Мои карты</p>
        <div className={styles.playerHand__cards}>
          <Cards
            cards={currentPlayer?.hand}
            selectedCardId={selectedCardId}
            handleCardClick={handleCardClick}
            handleCardDoubleClick={handleCardDoubleClick}
          />
        </div>
        <Actions selectedCardId={selectedCardId} />
      </div>
    )
  }

  return <>{renderHand()}</>
}

export default PlayerHand
