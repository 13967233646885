import { Routes, Route, BrowserRouter } from 'react-router-dom'
import React, { useEffect } from 'react'
import HomePage from 'components/HomePage/HomePage'
import ConnectPlayer from 'components/ConnectToGame/ConnectPlayer'
import WaitingPlayers from 'components/WaitingScreens/WaitingPlayers'
import Game from 'components/Game/Game'
import ConnectAdmin from 'components/ConnectToGame/ConnectAdmin'
import Hand from 'components/Hand/Hand'
import { GET_GAME } from 'constants/urls'
import {
  CONNECT_ADMIN_ROUTE,
  CONNECT_PLAYERS_ROUTE,
  GAME,
  HAND,
  MAIN_ROUTE,
  RULES,
  WAITING_PLAYERS_ROUTE,
} from 'constants/routes'
import { GAME_ID_KEY, UUID_KEY } from 'constants/keys'
import { useWebSocket } from 'ws/WebSocketContext'
import { iWebSocketContext } from './types/ws'
import 'styles/styles.scss'
import Rules from 'components/Rules/Rules'

function App() {
  const {
    game,
    currentPlayer,
    error,
    sendMessage,
    connectWebSocket,
    disconnectWebSocket,
  }: iWebSocketContext = useWebSocket()

  useEffect(() => {
    if (game) {
      console.log('Статус игры обновлён:', game)
    }

    if (currentPlayer) {
      console.log('Текущий игрок:', currentPlayer)
    }

    if (error) {
      console.log('Ошибки:', error)
    }
  }, [game, currentPlayer, error])

  useEffect(() => {
    const connect = async () => {
      const uuid = localStorage.getItem(UUID_KEY)
      const gameId = localStorage.getItem(GAME_ID_KEY)
      if (uuid && gameId) {
        await connectWebSocket(uuid, gameId)
        sendMessage(GET_GAME, { uuid, gameId })

        // Подписываемся на событие beforeunload, чтобы закрыть соединение перед выгрузкой страницы
        window.addEventListener('beforeunload', disconnectWebSocket)
      }
    }

    connect().then()

    // Функция очистки вызывается при размонтировании компонента
    return () => {
      window.removeEventListener('beforeunload', disconnectWebSocket)
      disconnectWebSocket()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={MAIN_ROUTE} element={<HomePage />} />
          <Route path={CONNECT_PLAYERS_ROUTE} element={<ConnectPlayer />} />
          <Route path={CONNECT_ADMIN_ROUTE} element={<ConnectAdmin />} />
          <Route path={WAITING_PLAYERS_ROUTE} element={<WaitingPlayers />} />
          <Route path={GAME} element={<Game />} />
          <Route path={HAND} element={<Hand />} />
          <Route path={RULES} element={<Rules />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
