import React from 'react'
import PageTemplate from '../PageTemplate/PageTemplate'
import { jsonStringify } from 'helpers/helpers'
import { status } from 'constants/enums'
import { useWebSocket } from 'ws/WebSocketContext'
import Loader from 'components/Loader/Loader'

const Game = () => {
  const { game } = useWebSocket()

  return (
    <PageTemplate>
      {game?.status === status.MAKING_WORD ? jsonStringify(game) : <Loader />}
    </PageTemplate>
  )
}

export default Game
