import { createContext, useContext } from 'react'
import { iWebSocketContext } from '../types/ws'

const WebSocketContext = createContext<iWebSocketContext | null>(null)

// Хук для использования контекста WebSocket
export const useWebSocket = () => {
  const context = useContext(WebSocketContext)

  if (!context) {
    const placeholderFunctions = () => {
      throw new Error('WebSocketContext is not yet available.')
    }

    return {
      game: null,
      currentPlayer: null,
      connectWebSocket: placeholderFunctions,
      disconnectWebSocket: placeholderFunctions,
      sendMessage: placeholderFunctions,
      isAdmin: false,
      isPlayer: false,
    }
  }

  return context
}

export default WebSocketContext
