import React, { useState, useRef, useCallback, useEffect } from 'react'
import SockJS from 'sockjs-client'
import { Client, IFrame, Stomp } from '@stomp/stompjs'
import WebSocketContext from './WebSocketContext'
import { api, http } from 'constants/api'
import {
  TOPIC_CURRENT_PLAYER,
  TOPIC_ERRORS,
  TOPIC_GAME_STATUS,
} from 'constants/urls'
import { jsonParse } from 'helpers/helpers'
import { iGame, iPlayer } from 'types/game'
import { iChildren, iError } from 'types/common'
import { iWebSocketContext } from 'types/ws'

const subscribeToTopic = (
  client: Client | null,
  topic: string,
  callback: (message: any) => void
) => {
  if (client && client.connected) {
    const subscription = client.subscribe(topic, callback)

    console.log(
      `Подписка на топик ${topic} успешно выполнена, ID подписки: ${subscription.id}`
    )
    return subscription // Возвращаем объект подписки для возможного управления
  } else {
    console.log(
      `Не удалось подписаться на топик ${topic}: клиент WebSocket не подключен.`
    )
    return null // Или можете вызвать reject, если используете промисы
  }
}

export const WebSocketProvider = ({ children }: iChildren) => {
  const [game, setGame] = useState<iGame | null>(null)
  const [currentPlayer, setCurrentPlayer] = useState<iPlayer | null>(null)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isPlayer, setIsPlayer] = useState<boolean>(false)
  const [error, setError] = useState<iError | null>(null)

  const clientRef = useRef<Client | null>(null)

  // Функция для инициализации подключения к WebSocket
  const connectWebSocket = (uuid: string, gameId: string) => {
    return new Promise((resolve, reject): void => {
      if (clientRef.current) {
        // Если соединение уже установлено, разрешаем промис
        resolve(true)
      } else {
        // Иначе, создаем новое соединение
        const socket = new SockJS(`${http}${api}`)
        const client = Stomp.over(socket)
        clientRef.current = client

        client.connect(
          {},
          (frame: IFrame) => {
            console.log('Connected: ' + frame)

            // Подписка на топик статуса игры
            subscribeToTopic(
              clientRef.current,
              TOPIC_GAME_STATUS(gameId),
              (message) => {
                setGame(jsonParse(message.body))
              }
            )

            // Подписка на  топик текущего юзера
            subscribeToTopic(
              clientRef.current,
              TOPIC_CURRENT_PLAYER(uuid),
              (message) => {
                setCurrentPlayer(jsonParse(message.body))
              }
            )

            // Подписка на  топик с ошибками
            subscribeToTopic(
              clientRef.current,
              TOPIC_ERRORS(uuid),
              (message) => {
                setError(jsonParse(message.body))
              }
            )

            resolve(true) // Разрешаем промис, когда соединение установлено
          },
          (error: Error) => {
            console.log('WebSocket connection error: ', error)
            reject(error) // Отклоняем промис при ошибке подключения
          }
        )
      }
    })
  }

  // Функция для отключения WebSocket
  const disconnectWebSocket = useCallback(() => {
    if (clientRef.current) {
      clientRef.current.deactivate().then(() => {
        console.log('Disconnected')
        clientRef.current = null
      })
    }
  }, [])

  // Функция для отправки сообщений
  const sendMessage = useCallback(
    (destination: string, message: any, headers = {}) => {
      if (clientRef.current) {
        clientRef.current.publish({
          destination,
          headers,
          body: JSON.stringify(message),
        })
      }
    },
    []
  )

  useEffect(() => {
    const isAdmin = currentPlayer?.id === game?.currentLeaderId
    const isPlayer = currentPlayer?.id !== game?.currentLeaderId

    setIsAdmin(isAdmin)
    setIsPlayer(isPlayer)

    return () => {
      setIsAdmin(false)
      setIsPlayer(false)
    }
  }, [currentPlayer, game])

  // Предоставление функций и состояний контекста
  const contextValue: iWebSocketContext | null = {
    game,
    currentPlayer,
    error,
    connectWebSocket,
    disconnectWebSocket,
    sendMessage,
    isAdmin,
    isPlayer,
  }

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  )
}
