import React, { useState } from 'react'
import { Input } from 'antd'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import PageTemplate from '../PageTemplate/PageTemplate'
import { CREATE_GAME } from 'constants/urls'
import { GAME_ID_KEY, UUID_KEY } from 'constants/keys'
import { WAITING_PLAYERS_ROUTE } from 'constants/routes'
import { generateUuid } from 'helpers/helpers'
import { useWebSocket } from 'ws/WebSocketContext'
import Loader from 'components/Loader/Loader'
import { useTimeoutLoading } from 'helpers/hooks'

const ConnectAdmin = () => {
  const navigate = useNavigate()
  const { connectWebSocket, sendMessage } = useWebSocket()

  const [uuid] = useState(() => {
    return localStorage.getItem(UUID_KEY) || generateUuid(UUID_KEY)
  })

  const [gameId] = useState(() => {
    return localStorage.getItem(GAME_ID_KEY) || generateUuid(GAME_ID_KEY)
  })

  const [name, setName] = useState<string>('')

  const onInputChange = (value: string) => {
    setName(value)
  }

  const { isLoading, setIsLoading } = useTimeoutLoading(5000)

  const onConnect = async () => {
    setIsLoading(true)
    await connectWebSocket(uuid, gameId)
    sendMessage(CREATE_GAME, { username: name, uuid, gameId })
    navigate(WAITING_PLAYERS_ROUTE)
  }

  return (
    <PageTemplate>
      <div className="flex">
        {isLoading ? (
          <Loader status="Создание игры..." />
        ) : (
          <>
            <Input
              placeholder="Введите имя игрока"
              className="input"
              onChange={(event) => onInputChange(event.target.value)}
            />
            <Button className="button" type="primary" onClick={onConnect}>
              Начать игру
            </Button>
          </>
        )}
      </div>
    </PageTemplate>
  )
}

export default ConnectAdmin
