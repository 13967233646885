import React from 'react'
import styles from './Loader.module.scss'
import loaderImage from 'assets/loader.svg'

interface iLoader {
  status?: string
  isFixed?: boolean
}

const Loader = ({ status = 'Ожидание...', isFixed = true }: iLoader) => {
  return (
    <div className={`${styles.loader} ${isFixed ? styles.fixed : ''}`}>
      <div
        style={{
          backgroundImage: `url("${loaderImage}")`,
        }}
        className={styles.loading}
      />
      <div className={styles.status}>{status}</div>
    </div>
  )
}

export default Loader
