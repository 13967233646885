import React from 'react'
import { iMainMenu, mainMenu } from 'constants/lists'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import PageTemplate from '../PageTemplate/PageTemplate'
import { CONNECT_ADMIN_ROUTE, CONNECT_PLAYERS_ROUTE, RULES } from 'constants/routes'
import { logoutGame } from 'helpers/helpers'
import styles from './HomePage.module.scss'

const HomePage = () => {
  const navigate = useNavigate()

  const onClickMenu = (item: iMainMenu) => {
    if (item.id === 1) {
      navigate(CONNECT_ADMIN_ROUTE)
    }

    if (item.id === 2) {
      navigate(CONNECT_PLAYERS_ROUTE)
    }

    if (item.id === 5) {
      logoutGame()
    }

    if (item.id === 3) {
      navigate(RULES)
    }
  }

  return (
    <PageTemplate>
      <div className={styles.homePage}>
        <div className={styles.menu}>
          {mainMenu?.map((item: iMainMenu) => (
            <Button
              key={item.id}
              className="button"
              type="primary"
              onClick={() => onClickMenu(item)}
            >
              {item.text}
            </Button>
          ))}
        </div>
      </div>
    </PageTemplate>
  )
}

export default HomePage
