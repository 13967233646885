import React from 'react'
import type { iCard } from 'types/game'
import Card from 'components/Card/Card'

interface iCards {
  cards: iCard[] | undefined
  selectedCardId: string | null
  handleCardClick: (cardId: string) => void
  handleCardDoubleClick?: (card: iCard) => void
  isVoting?: boolean
}

const Cards = ({
  cards,
  selectedCardId,
  handleCardClick,
  handleCardDoubleClick,
  isVoting = false,
}: iCards) => {
  return (
    <>
      {cards?.map((card: iCard) => {
        return (
          <Card
            key={card.id}
            isSelected={card?.id === selectedCardId}
            card={card}
            onClick={() => handleCardClick(card?.id)}
            onDoubleClick={() =>
              handleCardDoubleClick && handleCardDoubleClick(card)
            }
            isVoting={isVoting}
          />
        )
      })}
    </>
  )
}

export default Cards
