import React from 'react'
import { Button, Typography } from 'antd'
import PageTemplate from '../PageTemplate/PageTemplate'
import styles from './Rules.module.scss'
import { useNavigate } from 'react-router-dom'
const { Title } = Typography

const Rules = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate('/')
  }

  return (
    <PageTemplate>
      <div className={styles.rules}>
        <Button type="text" onClick={goBack}>
          {`<-`} В меню
        </Button>
        <Title className="title" level={4}>
          Правила игры
        </Title>
        <p className={styles.heading}>Цель игры:</p>
        <p className={styles.desc}>Собрать наибольшее количество очков.</p>
        <p className={styles.heading}>Количество игроков:</p>
        <p className={styles.desc}>3-9 человек.</p>
        <p className={styles.heading}>Подготовка к игре:</p>
        <p className={styles.desc}>
          Создайте новую игру и скажите другим участникам код игры, отображаемый
          на экране, чтобы они могли нажать на своих устройствах кнопку "Войти"
          и присоединиться к игре, введя полученный код. Каждому игроку
          раздается количество карт, равное количеству игроков. Первым ведущим
          становится тот, кто создал игру, а далее очередь передается в
          зависимости от очередности присоединения к игре.
        </p>
        <p className={styles.heading}>Ход игры:</p>
        <p className={styles.desc}>
          Ведущий выбирает одну из своих карт и тайно от всех придумывает слово,
          которое ассоциируется с изображением на карте. Это слово ведущий
          вводит и , выбрав карту, делает ход. Остальные игроки выбирают из
          своих карт ту, которая, по их мнению, может быть ассоциирована с
          словом ведущего и тоже делают ход. Собранные карты перемешиваются и
          выкладываются лицом вверх. Игроки (кроме ведущего) голосуют за ту
          карту, которую считают исходной картой ведущего. После того как все
          проголосовали, игра раскрывает, какая карта принадлежала ведущему, и
          всем начисляются очки.
        </p>
        <p className={styles.heading}>Подсчет очков:</p>
        <p className={styles.desc}>
          Если все или ни один из игроков не угадал карту ведущего, ведущий
          теряет 3 очка, а остальные игроки получают по 1 очку. Если хотя бы
          один игрок угадал карту ведущего, но не все, то ведущий и игроки,
          угадавшие карту, получают по 3 очка. Игроки, чьи карты были ошибочно
          выбраны в качестве карты ведущего, получают по 1 дополнительному очку
          за каждый такой голос.
        </p>
        <p className={styles.heading}>Окончание игры:</p>
        <p className={styles.desc}>
          Игра продолжается до тех пор, пока кто-то не наберет 50 очков.
        </p>
      </div>
    </PageTemplate>
  )
}

export default Rules
