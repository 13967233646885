import React, { useEffect, useState } from 'react'
import type { iPlayerCard } from 'types/game'
import Card from 'components/Card/Card'
import { Modal } from 'antd'
import { useWebSocket } from 'ws/WebSocketContext'
import { status } from 'constants/enums'

interface iCardVotes {
  [cardId: string]: number[]
}

const RoundInfo = () => {
  const { game } = useWebSocket()

  const [isShow, setIsShow] = useState<boolean>(false)
  const [roundInfo, setRoundInfo] = React.useState<iPlayerCard[]>([])
  const [cardVotes, setCardVotes] = useState<iCardVotes | null>(null)

  const getVotes = () => {
    const cardVotesTmp: iCardVotes = {}

    // Проходим по массиву голосов
    game?.roundState?.votes?.forEach((vote: iPlayerCard) => {
      const cardId = vote.id
      const playerId = vote.playerId

      // Если объект еще не содержит массив для данного cardId, создаем его
      if (!cardVotesTmp[cardId]) {
        cardVotesTmp[cardId] = []
      }

      // Добавляем playerId в массив для данного cardId
      cardVotesTmp[cardId].push(playerId)
    })

    setCardVotes(cardVotesTmp)
  }

  const onCloseModal = () => {
    setIsShow(false)
  }

  const isLeaderCard = (cardId: string) => {
    const leaderId = game?.currentLeaderId
    const result = roundInfo?.filter(
      (item) => item.playerId === leaderId && item.id === cardId
    )

    return result && result.length > 0
  }

  useEffect(() => {
    if (game?.status === status.ROUND_END) {
      setIsShow(true)
      setRoundInfo(game?.roundState?.playerCardDTO)
      getVotes()
    }
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, [game?.status])

  return (
    <>
      <Modal open={isShow} onCancel={onCloseModal} footer={<></>}>
        {roundInfo.map((card) => {
          return (
            <Card
              card={card}
              isVoting={true}
              votes={cardVotes?.[card.id]}
              isLeaderCard={isLeaderCard(card.id)}
            />
          )
        })}
      </Modal>
    </>
  )
}

export default RoundInfo
