import React from 'react'
import { useWebSocket } from 'ws/WebSocketContext'
import { iPlayer } from 'types/game'

const Players = () => {
  const { game } = useWebSocket()

  return (
    <div>
      <div style={{fontSize: 20}}>Игроки:</div>
      {game?.players?.map((player: iPlayer) => {
        return <p key={player.id}>{player?.name}</p>
      })}
    </div>
  )
}

export default Players
