import React from 'react'
import logo from 'assets/artcard-logo.svg'
import bgImage from 'assets/bg-image.svg'
import { Link } from 'react-router-dom'
import { iChildren } from 'types/common'

const PageTemplate = ({ children }: iChildren) => {
  return (
    <div className="container">
      <div className="bg-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        />
      </div>
      <div className="logo-container">
        <Link
          to="/"
          className="logo"
          style={{
            backgroundImage: `url(${logo})`,
          }}
        ></Link>
      </div>
      {children}
    </div>
  )
}

export default PageTemplate
