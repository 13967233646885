import React from 'react'
import { Button } from 'antd'
import { logoutGame } from 'helpers/helpers'

const AfterGame = () => {
  return (
    <div className="flex">
      <Button className="button" type="primary" onClick={logoutGame}>
        Выйти
      </Button>
      <Button className="button" type="primary" onClick={logoutGame}>
        Повторить игру
      </Button>
    </div>
  )
}

export default AfterGame
