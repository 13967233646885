import React, { useState } from 'react'
import styles from './Voting.module.scss'
import Card from 'components/Card/Card'
import Actions from './Actions'
import GameInfo from '../Hand/GameInfo'
import { useWebSocket } from 'ws/WebSocketContext'
import { iPlayerCard } from 'types/game'

const Voting = () => {
  const { game } = useWebSocket()

  const [selectedCardId, setSelectedCardId] = useState<string | null>(null)

  const handleCardClick = (cardId: string) => {
    setSelectedCardId(cardId)
  }

  return (
    <>
      <GameInfo />
      <div className={styles.voting__cards}>
        {game?.roundState?.playerCardDTO?.map((card: iPlayerCard) => {
          return (
            <>
              {
                <Card
                  key={card?.id}
                  isSelected={card?.id === selectedCardId}
                  card={card}
                  onClick={() => handleCardClick(card?.id)}
                />
              }
            </>
          )
        })}
      </div>
      <div className="mt-2">
        <Actions selectedCardId={selectedCardId} />
      </div>
    </>
  )
}

export default Voting
