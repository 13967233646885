import React from 'react'
import PageTemplate from '../PageTemplate/PageTemplate'
import PlayerHand from './PlayerHand'
import { status } from 'constants/enums'
import Voting from '../Voting/Voting'
import Loader from '../Loader/Loader'
import { checkIfUserVoted, stateToString } from 'helpers/helpers'
import Players from '../Players/Players'
import { useWebSocket } from 'ws/WebSocketContext'
import AfterGame from 'components/AfterGame/AfterGame'
import Table from 'components/Table/Table'
import RoundInfo from 'components/RoundInfo/RoundInfo'

const Hand = () => {
  const { game, isPlayer, isAdmin, currentPlayer, error } = useWebSocket()

  const checkIfUserChose = () => {
    const checked = game?.roundState?.playerCardDTO?.filter(
      (playerCard) => playerCard.playerId === currentPlayer?.id
    )
    return checked ? checked.length > 0 : false
  }

  const getWinner = () => {
    return game?.players?.filter((player) => player.id === game.winnerId)[0]
  }

  const getLeaderName = () => {
    return game?.players?.filter(
      (player) => player.id === game.currentLeaderId
    )[0].name
  }

  return (
    <PageTemplate>
      {game?.status !== status.WAITING && (
        <div>
          <p>
            Ведущий: <span>{getLeaderName()}</span>
          </p>
          <p>
            Мой текущий счет: <span>{currentPlayer?.score}</span>
          </p>
        </div>
      )}
      {error && (
        <>
          <div className="mb-2">{error.description}</div>
          <AfterGame />
        </>
      )}
      {game?.status === status.MAKING_WORD && isPlayer && (
        <div className="mb-2">Ведущий загадывает слово. Ожидайте</div>
      )}
      {game?.status === status.CHOOSING && isAdmin && (
        <div className="mb-2">Ждите пока игроки выберут карты</div>
      )}
      {game?.status === status.CHOOSING && isPlayer && !checkIfUserChose() && (
        <div className="mb-2">
          Выберите карту, подходящую к загаданной ассоциации
        </div>
      )}
      <RoundInfo />
      {game?.status === status.VOTING &&
        isPlayer &&
        !checkIfUserVoted(game?.roundState?.votes, currentPlayer?.id) && (
          <div className="mb-2">Проголосуйте за наиболее подходящую карту</div>
        )}
      {game?.status === status.CHOOSING && <Table />}
      {(game?.status === status.MAKING_WORD ||
        game?.status === status.CHOOSING) && <PlayerHand />}
      {game?.status === status.VOTING && <Voting />}
      {game?.status === status.WAITING && (
        <>
          <Loader status={stateToString(game?.status)} />
          <Players />
        </>
      )}
      {game?.status === status.GAME_OVER && (
        <>
          <div className="mb-2">{getWinner()?.name} победил!</div>
          <div className="mb-2">Очков: {getWinner()?.score}</div>
          <AfterGame />
        </>
      )}
    </PageTemplate>
  )
}

export default Hand
