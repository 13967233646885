import React from 'react'
import { useWebSocket } from 'ws/WebSocketContext'
import { getPlayerName } from 'helpers/helpers'

const Table = () => {
  const { game } = useWebSocket()

  return (
    <div className="cards_wrapper">
      <p>Игроки, которые сделали свой выбор:</p>
      <div className="cards_wrapper__cards">
        {game?.roundState?.playerCardDTO?.map((card) => {
          return <div>{getPlayerName(game?.players, card?.playerId)}</div>
        })}
      </div>
    </div>
  )
}

export default Table
