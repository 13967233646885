import React, { useState } from 'react'
import { Input } from 'antd'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { generateUuid } from 'helpers/helpers'
import PageTemplate from '../PageTemplate/PageTemplate'
import { ADD_PLAYER } from 'constants/urls'
import { HAND } from 'constants/routes'
import { GAME_ID_KEY, UUID_KEY } from 'constants/keys'
import { useWebSocket } from 'ws/WebSocketContext'
import { iKeyStringValueString } from 'types/common'
import { useTimeoutLoading } from 'helpers/hooks'
import Loader from 'components/Loader/Loader'

const ConnectPlayer = () => {
  const navigate = useNavigate()
  const { connectWebSocket, sendMessage } = useWebSocket()
  const { isLoading, setIsLoading } = useTimeoutLoading(5000)

  const [uuid] = useState(() => {
    return localStorage.getItem(UUID_KEY) || generateUuid(UUID_KEY)
  })

  const [data, setData] = useState<iKeyStringValueString>({})

  const onInputChange = (value: string, key: string) => {
    setData({ ...data, [key]: value })
  }

  const onConnect = async () => {
    if (data?.gameId) {
      setIsLoading(true)
      localStorage.setItem(GAME_ID_KEY, data.gameId)
      await connectWebSocket(uuid, data.gameId)
      sendMessage(ADD_PLAYER, { ...data, uuid })
      navigate(HAND)
    }
  }

  return (
    <PageTemplate>
      {isLoading ? (
        <Loader status='Подключение к игре...'/>
      ) : (
        <div className="flex">
          <Input
            className="input"
            placeholder="Введите id игры"
            onChange={(event) => onInputChange(event.target.value, 'gameId')}
          />
          <Input
            className="input"
            placeholder="Введите имя игрока"
            onChange={(event) => onInputChange(event.target.value, 'username')}
          />
          <Button type="primary" className="button" onClick={onConnect}>
            Присоединиться
          </Button>
        </div>
      )}
    </PageTemplate>
  )
}

export default ConnectPlayer
