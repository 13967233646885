import { useEffect, useState } from "react"

const useTimeoutLoading = (timeoutMs: number) => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading) {
            return
        }

        const timeoutId = setTimeout(() => {
            setIsLoading(false)
        }, timeoutMs)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [isLoading, timeoutMs])

    return { isLoading, setIsLoading }
}

export { useTimeoutLoading }
