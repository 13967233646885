import React from 'react'
import { status } from 'constants/enums'
import { Typography } from 'antd'
import { useWebSocket } from 'ws/WebSocketContext'
const { Title } = Typography

const GameInfo = () => {
  const { game } = useWebSocket()

  return (
    <>
      {(game?.status === status.CHOOSING || game?.status === status.VOTING) && (
        <Title level={4}>Загаданное слово: {game?.roundState?.word}</Title>
      )}
    </>
  )
}

export default GameInfo
