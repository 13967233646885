import React, { useEffect, useState } from 'react'
import { status } from 'constants/enums'
import { useWebSocket } from 'ws/WebSocketContext'
import styles from './Card.module.scss'
import { iCard } from '../../types/game'
import VoteInfo from 'components/Card/VoteInfo'

interface iCardPage {
  card: iCard
  isSelected?: boolean
  onClick?: () => void
  onDoubleClick?: () => void
  isVoting?: boolean
  votes?: number[]
  isLeaderCard?: boolean
}

const Card = ({
  card,
  isSelected,
  onClick,
  onDoubleClick,
  isVoting = false,
  votes,
  isLeaderCard,
}: iCardPage) => {
  const { game, isAdmin, currentPlayer } = useWebSocket()

  const [isCanChoose, setIsCanChoose] = useState(true)

  useEffect(() => {
    if (
      (isAdmin && game?.status === status.VOTING) ||
      (isAdmin && game?.status === status.CHOOSING) ||
      (currentPlayer?.hand &&
        game?.players &&
        currentPlayer.hand.length < game.players.length &&
        game?.status !== status.VOTING)
    ) {
      setIsCanChoose(false)
    }

    return () => {
      setIsCanChoose(true)
    }
  }, [game, isAdmin, currentPlayer, card])

  return (
    <div
      key={card.id}
      className={`${styles.card} ${isSelected ? styles.card_selected : ''} ${
        !isCanChoose ? styles.no_vote : ''
      } ${isVoting && isLeaderCard ? styles.leaderCard : ''}`}
      onClick={() => isCanChoose && onClick && onClick()}
      onDoubleClick={() => isCanChoose && onDoubleClick && onDoubleClick()}
    >
      {isVoting && (
        <div className={styles.card__vote_info}>
          <VoteInfo votes={votes} />
        </div>
      )}
      <img className={`${styles.card__image}`} src={card.link} alt={card.id} />
    </div>
  )
}

export default Card
