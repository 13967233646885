import React from 'react'
import { useWebSocket } from 'ws/WebSocketContext'
import { getPlayerName } from 'helpers/helpers'

interface iVoteInfo {
  votes: number[] | undefined
}

const VoteInfo = ({ votes }: iVoteInfo) => {
  const { game } = useWebSocket()

  return (
    <>
      {game?.players && votes
        ? votes.map((vote) => {
            return <div>{getPlayerName(game.players, vote)}</div>
          })
        : 'Никто не проголосовал'}
    </>
  )
}

export default VoteInfo
