export interface iMainMenu {
  id: number
  text: string
}
export const mainMenu: iMainMenu[] = [
  { id: 1, text: 'Новая игра' },
  { id: 2, text: 'Войти в игру' },
  { id: 3, text: 'Правила' },
  { id: 4, text: 'Настройки' },
  { id: 5, text: 'Выход' },
]
